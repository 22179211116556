/*
TODO redo this using Prime Reacts theme designer @Dirk 8/23/22
*/

#essexPricingAppRoot .p-datatable .p-datatable-thead > tr > th {
	border: unset;
	overflow: visible;
}

#essexPricingAppRoot .p-button:focus {
	box-shadow: 0 0 0 0.2rem #ccc;
}

#essexPricingAppRoot .p-sidebar-close:focus {
	box-shadow: 0 0 0 0.1rem #ccc;
}

#essexPricingAppRoot .p-sidebar-close {
	color: #ccc;
}

@media print {
	@page {
		size: landscape;
	}
	#essexPricingAppRoot #matrixContainer .p-datatable .p-datatable-tbody > tr > td {
		font-size: 25px;
	}
	#essexPricingAppRoot #matrixContainer {
		max-height: unset;
		overflow-y: unset;
		zoom: 50%;
		width: 100%;
		margin: 0 0 0 0;
	}
	#essexPricingAppRoot #contentContainer {
		max-height: unset;
		overflow-y: unset;
		zoom: 80%;
		width: 100%;
		margin: 0 0 0 0;
	}
	#essexPricingAppRoot #pricingSummaryContentContainer {
		max-height: unset;
		overflow-y: unset;
		zoom: 68%;
		width: 100%;
		margin: 0 0 0 0;
	}
	#essexPricingAppRoot #unitTypeHeadingMargin {
		page-break-after: always;
		margin-top: 20px;
	}
	#essexPricingAppRoot #unitTypeHeadingMargin2 {
		margin-top: -20px;
	}
	#essexPricingAppRoot #matrixBreak {
		page-break-after: always;
		margin-top: 2rem;
	}
	#essexPricingAppRoot #matrixFilters,
	#headerButtons,
	#unitAvailabilityFilters,
	#concessionsReportFilters,
	#staleUnitReportFilters,
	#pricingAdjustmentSummaryReportFilters {
		display: none;
	}

	#pricingMatrixUnavailableCheckbox {
		display: none;
	}
}

#essexPricingAppRoot #unitExpansionTable .p-datatable .p-datatable-thead > tr > th {
	height: unset;
}

#essexPricingAppRoot .p-datatable .p-sortable-column.p-highlight {
	background: transparent;
	color: inherit;
}

#essexPricingAppRoot .p-datatable .p-component.p-dropdown .p-dropdown-trigger {
	background-color: #777;
	border-radius: none;
}

#essexPricingAppRoot .p-paginator {
	background-color: unset;
	border: unset;
	padding-top: 20px;
}

#essexPricingAppRoot .p-paginator .p-component.p-dropdown {
	margin-left: 3em;
}

#essexPricingAppRoot .p-datatable .p-datatable-tbody > tr > td {
	border: unset;
	border-bottom: 1px solid #ddd;
	font-size: 14px;
}

#essexPricingAppRoot .p-datatable .p-datatable-tbody > tr > td .p-inputtext,
#essexPricingAppRoot .p-datatable .p-datatable-tbody > tr > td .p-inputtextarea {
	font-size: 14px;
	line-height: 1;
	min-height: 30px;
}

#essexPricingAppRoot .p-datatable .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	background-color: #777;
}

#essexPricingAppRoot .p-paginator .p-dropdown .p-dropdown-label {
	padding-right: 9px;
}

#essexPricingAppRoot .p-component.p-dropdown .p-dropdown-trigger,
#essexPricingAppRoot .p-multiselect .p-multiselect-trigger {
	background-color: #c75109;
	color: white;
}

#essexPricingAppRoot .p-dropdown-clearable .p-inputtext {
	padding-right: 2.5em;
}
#essexPricingAppRoot .p-component.p-dropdown .p-dropdown-clear-icon {
	right: 2.7em;
	color: #2e2f31;
}

#essexPricingAppRoot .p-inputtext,
#essexPricingAppRoot .p-inputtextarea {
	min-height: 32px;
	width: 100%;
	color: #2e2f31;
}

#essexPricingAppRoot .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
#essexPricingAppRoot .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
	background-color: #c75109;
}

#essexPricingAppRoot .p-datatable .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
#essexPricingAppRoot .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
	background-color: #aaa;
}

#essexPricingAppRoot .p-dropdown-panel {
	border-color: white;
}

#essexPricingAppRoot .p-button {
	cursor: pointer;
	background-color: #c75109;
	border-color: #c75109;
}

#essexPricingAppRoot .p-button.p-button-secondary {
	color: #ffffff;
	background: #607d8b;
	border: 1px solid #607d8b;
}
#essexPricingAppRoot .p-button.p-button-secondary:hover {
	color: #ffffff;
	background: #41535c;
	border: 1px solid #262f33;
}

/* buttons that are not fully colored in (link buttons that appear like links) */
#essexPricingAppRoot .essexPricingPlatformLinkButton.p-button {
	background-color: transparent;
	border-color: transparent;
	color: #c75109;
	padding: 0;
	line-height: 1.15;
}

/* remove the padding around link buttons */
#essexPricingAppRoot .essexPricingPlatformLinkButton.p-button.p-button-icon-only .p-button-text {
	padding: 0;
}

#essexPricingAppRoot .mainMenuItems .p-button {
	cursor: pointer;
	color: #ccc;
	background-color: transparent;
	border-color: transparent;
	padding-bottom: 16px;
	margin-bottom: -16px;
	padding-top: 16px;
	margin-top: -16px;
	height: unset;
}

#essexPricingAppRoot .mainMenuItems .p-button .p-button-text {
	padding-top: 0;
	padding-bottom: 0;
	font-size: 14px;
	display: block;
	margin-left: -1em;
}

#essexPricingAppRoot .mainMenuItems .p-menu.p-component {
	background-color: rgba(0, 131, 143);
	border: none;
}

#essexPricingAppRoot .mainMenuItems .p-menu.p-component .p-menu-list {
	background-color: rgba(0, 0, 0, 0.5);
}

#essexPricingAppRoot .mainMenuItems .p-menu.p-component .p-menu-list .p-menuitem-link .p-menuitem-text {
	color: #ccc;
	font-size: 14px;
}

#essexPricingAppRoot .mainMenuItems .p-menu.p-component .p-menu-list .p-menuitem-link:hover {
	background-color: #ccc;
}

#essexPricingAppRoot .mainMenuItems .p-menu.p-component .p-menu-list .p-menuitem-link:hover .p-menuitem-text {
	color: black;
}

/* SelectButton background and color when selection is inactive */
#essexPricingAppRoot .p-selectbutton .p-button {
	cursor: pointer;
	color: #ffffff;
	background-color: #666666;
	border-color: #666666;
}

/* SelectButton background and color when selection is active */
#essexPricingAppRoot .p-selectbutton .p-button.p-highlight {
	cursor: pointer;
	background-color: #c75109;
	border-color: #c75109;
}

#essexPricingAppRoot .p-datatable .p-sortable-column .p-sortable-column-icon {
	color: #2e2f31;
}

#essexPricingAppRoot .p-component.p-datatable {
	/* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); */
	border-radius: 10px;
	border: unset;
}

#essexPricingAppRoot .p-datatable .p-datatable-header,
#essexPricingAppRoot .p-datatable .p-datatable-footer {
	background-color: unset;
	border: unset;
}

/* Rows on tables highlighted to show property exceptions */
#essexPricingAppRoot .p-datatable .p-datatable-tbody > tr.highlightRowForPropertyException {
	background-color: #c7510920;
	color: black;
	font-weight: bold;
}

/* Rows on tables highlighted to show unit exceptions */
#essexPricingAppRoot .p-datatable .p-datatable-tbody > tr.highlightRowForUnitException {
	background-color: #ffe39930;
	color: black;
	font-weight: bold;
}

/* Rows on tables bolded in black to show property exceptions */
#essexPricingAppRoot .p-datatable .p-datatable-tbody > tr.highlightRowForPropertyException > td {
	color: black;
}

#essexPricingAppRoot .p-row-toggler:focus {
	outline: none;
	border: none;
	box-shadow: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

/* Background color for frozen rows inside of tables (e.g. review summary screen) */
#essexPricingAppRoot
	.p-datatable-scrollable-header-box
	.p-datatable-scrollable-header-table
	.p-datatable-tbody
	.p-datatable-row {
	background-color: #666666;
}

/* Font color for frozen rows inside of tables (e.g. review summary screen) */
#essexPricingAppRoot
	.p-datatable-scrollable-header-box
	.p-datatable-scrollable-header-table
	.p-datatable-tbody
	.p-datatable-row
	td {
	font-weight: bold;
	color: white;
}

#essexPricingAppRoot .p-checkbox .p-checkbox-box.p-highlight {
	background-color: #c75109;
	border-color: #c75109;
}

#essexPricingAppRoot .p-datatable {
	position: relative;
}
#essexPricingAppRoot .p-datatable .p-checkbox .p-checkbox-box.p-highlight {
	background-color: #00838f;
	border-color: #00838f;
}

#essexPricingAppRoot .p-datatable .p-highlight {
	background-color: #529fa7;
	color: black;
}

#essexPricingAppRoot .p-datepicker table td > span.p-highlight {
	background-color: #c75109;
}

#essexPricingAppRoot span.unitgroup-selection-wrapper {
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	align-self: center;
}
#essexPricingAppRoot span.unitgroup-dropdown-item {
	display: flex;
	gap: 5px;
	align-self: center;
}
#essexPricingAppRoot span.unitgroup-dropdown-description {
	font-size: 12px !important;
	color: gray !important;
	display: flex;
	overflow: hidden;
	align-self: center;
	text-align: inherit;
}

@keyframes p-progress-spinner-color {
	100%,
	0% {
		stroke: #c75109;
	}
}

#essexPricingAppRoot .p-tabview .p-tabview-panels {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	border: unset;
	border-radius: unset;
}

#essexPricingAppRoot .p-tabview.p-tabview-top .p-tabview-nav li a {
	border: unset;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

#essexPricingAppRoot .p-tabview .p-tabview-nav li {
	padding: 5px 5px 0 5px;
	overflow: hidden;
}

#essexPricingAppRoot .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a {
	background-color: #c75109;
}

#essexPricingAppRoot .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a {
	background-color: #dfdfdf;
}

#essexPricingAppRoot .p-tabview.p-tabview-top > .p-tabview-nav li {
	margin-bottom: 2px;
}

#essexPricingAppRoot .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: #004147;
	border-color: #004147;
	color: #ffffff;
}

#essexPricingAppRoot .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	-webkit-box-shadow: 0 0 0 0.2rem #629499;
	box-shadow: 0 0 0 0.2rem #629499;
}

#essexPricingAppRoot .p-tooltip {
	z-index: 1;
}

#essexPricingAppRoot .p-tooltip.p-component.p-tooltip-arrow,
#essexPricingAppRoot .p-tooltip.p-component .p-tooltip-text {
	background-color: #444;
	border-right-color: #444;
	border-left-color: #444;
	font-size: small;
}

#essexPricingAppRoot td[class*="RightAlign"] input {
	text-align: right;
}

#essexPricingAppRoot .pi-comments {
	font-size: 2.25em;
}

#essexPricingAppRoot .p-scrollpanel-bar-y {
	margin-left: 9px;
}

#essexPricingAppRoot .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background-color: #c75109;
}

/* cursor on tool-tipped icons */
.pi[title] {
	cursor: help;
}

/* accordion */
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
	background-color: #c75109;
}

/*
	hide select all without hiding the whole panel
	Add spacing between check and dropdown button
*/

#unitAvailabilityFilters .p-multiselect-panel .p-multiselect-header .p-checkbox {
	visibility: hidden;
	margin-right: 0.5rem;
	display: none;
}

#unitAvailabilityFilters .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
	visibility: hidden;
	margin-right: 0.5rem;
	display: none;
}

#unitAvailabilityFilters .p-multiselect .p-multiselect-clear-icon {
	margin-right: 6px;
}

/*
	Hide multi-select's select all checkbox
	Add spacing between check and dropdown button
*/
#matrixFilters .p-multiselect-panel .p-multiselect-header .p-checkbox {
	visibility: hidden;
	margin-right: 0.5rem;
	display: none;
}

#matrixFilters .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
	visibility: hidden;
	margin-right: 0.5rem;
	display: none;
}

#matrixFilters .p-multiselect .p-multiselect-clear-icon {
	margin-right: 6px;
}

#matrixBreak .p-fieldset-legend {
	display: none;
}

/*
	Pricing automation overwrites
*/

#essexPricingAppRoot .pricingAutomation .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: #c75109;
	font-size: x-small;
}

#essexPricingAppRoot .pricingAutomation .p-datatable .p-sortable-column.p-highlight {
	background: hsl(0, 0%, 85%);
}

#essexPricingAppRoot .pricingAutomation .pi-sort-alt {
	color: hsl(0, 0%, 60%);
	font-size: x-small;
}

#essexPricingAppRoot .pricingAutomation .p-paginator .p-paginator-current {
	cursor: auto;
	padding: 0;
	color: var(--text-color);
}

#essexPricingAppRoot .pricingAutomation .skeleton {
	animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(0, 0%, 80%);
	}
	100% {
		background-color: hsl(0, 0%, 93%);
	}
}

#essexPricingAppRoot .pricingAutomation .p-checkbox .p-checkbox-box.p-highlight {
	background-color: transparent;
	border-color: #262f33;
}

#essexPricingAppRoot .pricingAutomation .p-multiselect-panel .pi-check {
	color: black;
}

#essexPricingAppRoot #overlay-checkbox .p-checkbox .p-checkbox-box.p-highlight {
	background-color: transparent;
	border-color: #262f33;
}

#essexPricingAppRoot #overlay-checkbox .pi-check,
#essexPricingAppRoot #overlay-checkbox .pi-times {
	color: black;
}

#essexPricingAppRoot .pricingAutomation .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	background: inherit;
	color: inherit;
}

#essexPricingAppRoot .pricingAutomation .p-dropdown-item .p-highlight {
	background: inherit;
	color: inherit;
}

#essexPricingAppRoot #overlay-checkbox .p-checkbox-box.p-focus {
	border: 1px solid black;
	box-shadow: 0 0 0 0;
}

#essexPricingAppRoot .pricingAutomation .p-multiselect-item:focus {
	border: none;
	box-shadow: 0 0 0 0;
}

#essexPricingAppRoot .pricingAutomation .p-paginator-page.p-highlight:focus {
	border: 2px solid black;
	box-shadow: 0 0 0 0;
}

#essexPricingAppRoot .pricingAutomation .p-datatable-loading-overlay {
	background: hsla(0, 0%, 80%, 0.4);
}

#essexPricingAppRoot .pricingAutomation .p-dialog .p-dialog-header {
	background: white;
}

#essexPricingAppRoot .pricingAutomation *:focus {
	box-shadow: none;
	outline: auto;
	outline-color: hsla(23, 91%, 41%, 0.4);
}

#essexPricingAppRoot .pricingAutomation .p-highlight {
	box-shadow: none;
}

#essexPricingAppRoot .pricingAutomation button:disabled,
button[disabled] {
	background-color: hsla(0, 0%, 90%, 0.8);
	color: #999999 !important;
	pointer-events: none;
}

#essexPricingAppRoot .p-focus {
	box-shadow: none;
	outline: auto;
	outline-color: hsla(23, 91%, 41%, 0.4);
}

#essexPricingAppRoot .pricingAutomation input[type="radio"] {
	accent-color: #262f33;
}

#essexPricingAppRoot .pricingAutomation .p-button:hover {
	background-color: hsla(23, 91%, 41%, 1);
}

#essexPricingAppRoot .pricingAutomation .p-button-text:hover {
	background-color: hsla(23, 91%, 41%, 1);
	color: white !important;
}

#essexPricingAppRoot .pricingAutomation .top-menu {
	width: calc(100% - 118px);
}

@media (max-width: 768px) {
	#essexPricingAppRoot .pricingAutomation .top-menu {
		width: 100%;
	}
}

.tblSuccessfulAutomation .p-datatable-thead > tr > th,
.tblSuccessfulAutomation .p-datatable-tbody > tr > td,
#pgSystemAutomated .p-datatable .p-datatable-thead > tr > th,
#pgSystemAutomated .p-datatable .p-datatable-tbody > tr > td,
#pgExclusions .p-datatable .p-datatable-thead > tr > th,
#pgExclusions .p-datatable .p-datatable-tbody > tr > td,
#pgSecondaryReview .p-datatable .p-datatable-thead > tr > th,
#pgSecondaryReview .p-datatable .p-datatable-tbody > tr > td {
	border: solid 1px #c8c8c8 !important;
}