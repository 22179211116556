.manage-unit-group {
	padding-left: 1rem;					
	div {					  
		.gap {
			display: flex;
			flex-direction: column;
			padding-top: 5px;	
			padding-bottom: 5px;	
		}
		div {																					
			.label {
				font-weight: 700;
				margin-left: 5px;
				margin-bottom: 5px;	
				display: inline-block;												
			}
			.star {
				color: red;
			}												
			input {
				display: flex;
				flex-direction: row;
				width: 400px !important;
				padding-top: 5px;				
			}
			.row {
				display: flex;	
				flex-flow: row wrap;
				width: 100%;
			}				
			.list-title {
				font-weight: 700;
				padding: 10px;
				width: 20rem;
				outline: 1px ridge;
				border-radius: .1rem;
				background-color: rgb(246, 243, 243);
			}
			.col-25 {
				display: flex;	
				flex-flow: column wrap;	
				width: 25%;				
			}
			.col-15 {
				display: flex;	
				flex-flow: column wrap;	
				width: 15%;				
			}
			.col-10 {
				display: flex;	
				flex-flow: column wrap;	
				width: 10%;				
			}
			.col-5 {
				display: flex;	
				flex-flow: column wrap;	
				width: 5%;							
			}
			.move-right-icon {
				margin-top: 80px;
			}
			.move-right-all-icon {
				margin-top: 10px;			
			}
			.move-left-icon {
				margin-top: 10px;
				margin-bottom: 10px;
			}
			.move-left-all-icon {
				margin-bottom: 80px;
			}
			.list-item {
				display: inline;
				font-size: 12px;
				font-weight: 500;
				.list-value {
					margin-left: 5px;
					font-size: 10px;
					color: rgb(181, 179, 179);
				}				
			}
			.delete-button {	
				margin-top: 10px;				
				padding: 10px;
				color: gray !important;
				background: #eaeaea !important;
			}
			.delete-button:hover {
				color: #F0F0F0 !important;
				background-color: rgb(199, 81, 9) !important
			}
		}
	}	
}

.cancel-button {
	color: gray !important;
	background: #F0F0F0 !important;
	margin-right: 20px;
}

.cancel-button:hover {
	color: #F0F0F0 !important;
	background: #c75109 !important;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
	margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
