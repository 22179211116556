.operatorFilterSection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px 8px;
	width:fit-content;
	/* background-color: lightblue; */
}

.operatorFilterSectionTitle {
	margin-bottom: 6px;
	white-space: nowrap;
	font-weight: bold;
}

.operatorFilter {
	display: flex;
	flex-direction: row;
	align-items: center;	
	gap: 8px 4px;
	/* background-color: orange; */
}

.operatorFilterLabel {
	white-space: nowrap;
	font-size: 12px;
	padding-left: 5px;
}

.filter {
	display: flex;
	flex-direction: column;
}