.card {
	border: 1px solid #bbb;
	border-radius: 3px;
	position: relative;
}

.title {
	background-color: #eee;
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.titleName {
	font-size: 1.15em;
	font-weight: bold;
	padding: 10px 16px;
}
/* .titleName > a.titleLink {
	color: #222;
} */
.titleActionIcons {
	padding: 5px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

.column {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: flex-start;
}

.reviewRow,
.dataRow,
.priceAdjustmentForm {
	padding: 10px 16px;
}

.dataRow {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	gap: 10px 15px;

	justify-content: space-around;
	align-content: space-between;
	align-items: flex-start;
	justify-items: center;
}

.dataRow div:first-child {
	justify-self: flex-start;
	align-self: flex-start;
}

.reviewRow {
	display: flex;
	align-content: center;
	flex-direction: column;
	align-items: flex-start;
}
.reviewRow .column {
	display: flex;
	align-content: center;
	align-items: center;
	gap: 10px;
	flex-direction: row;
}
.reviewRow small:first-child {
	padding-bottom: 3px;
}

.dataPoint span,
.dataPoint b {
	white-space: nowrap;
}
.dataPoint[title] {
	cursor: help;
}
.dataPointCard {
	display: flex;
	flex-direction: row;
	gap: 2px 10px;
	flex-wrap: wrap;
}

.dirty:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 3px;
	background-color: rgb(215, 99, 26);
	z-index: 5;
}

.unitGroupHighlight:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 3px;
	background-color: rgb(96, 183, 141);
	z-index: 5;
}

.input {
	height: 34px;
}
.comments {
	min-width: 280px;
	height: 34px;
	line-height: 1.4em !important;
	width: 99%;
}

.filterHeaders {
	display: flex;
	width: 100%;
	margin: 30px 0 50px 25px;
	align-items: center;
}
.filterItem {
	display: flex;
	flex-direction: column;
	margin-right: 40px;
}
.summaryInfo {
	margin-left: 30px;
	margin-bottom: 20px;
}
.applyButton {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 80px;
}

@media screen and (max-width: 1200px) {
	.dataRow {
		grid-template-columns: repeat(auto-fit, minmax(240px, 2fr));
		gap: 25px 20px;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-items: left;
		align-content: flex-start;
		justify-content: flex-start;
	}
}

.priceAdjustmentForm {
	display: flex;
	flex-direction: row;
	gap: 10px 20px;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	position: relative;
	border-top: 1px dotted #aaa;
}

/*
the buttons styles are all messed up on a global level
because they overwrote .p-button's background,
so we're doing something fancy here
*/

/* :global(#essexPricingAppRoot) button.undoButton:global(.p-button) {
	background: #DDD;
}

:global(#essexPricingAppRoot) button.undoButton:global(.p-button):hover {
	background: #BBB;
} */

button.undoButton :global(.p-button-label),
.undoButton :global(span.pi) {
	font-size: 0.9em !important;
}

.priceAdjustmentForm div {
	padding-top: 1.6rem; /*offset floating labels*/
}

.priceAdjustmentFormTitle {
	font-weight: bold;
}
.priceAdjustmentFormComments {
	flex-grow: 1;
}

.priceAdjustmentFormActionsArea {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;
}

.a:visited {
	color: #006;
}

@media screen and (max-width: 1075px) {
	.priceAdjustmentForm {
		padding-top: 10px;
	}

	div.priceAdjustmentFormTitle {
		flex-basis: 100%;
		padding-top: 0;
	}
}

@media screen and (max-width: 830px) {
	.priceAdjustmentFormComments {
		flex-grow: 1;
	}

	.priceAdjustmentFormComments .input {
		width: 100%;
		display: block;
	}
}

@media screen and (max-width: 600px) {
	.priceAdjustmentForm {
		display: block;
	}

	.input {
		width: 100%;
		display: block;
	}
}
