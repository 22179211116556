.changeLog {
	width: 100%;
	padding: 10px 16px;
}

.changeLog > h3 {
	padding: 0;
	margin: 0.5rem 0 0;
}

.changeLogAccordion {
	margin: 0;
	padding: 0;
	width: 100%;
}

.changeLogAccordionHeader {
	width: 100%;
	font-size: 13px;
	color: #222;
	text-decoration: none;
}

.changeLog :global(.p-accordion .p-accordion-header .p-accordion-header-link),
.changeLogAccordionHeader a.p-accordion-header-link,
.changeLogAccordionHeader a:hover,
.changeLog .p-accordion-header-link:hover {
	color: #222;
	text-decoration: none;
}

.changeLog :global(.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link),
.changeLog :global(.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link),
.changeLog :global(.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus) {
	background-color: #f0f0f0;
	color: #222;
	text-decoration: none;
	border: 1px solid #c8c8c8;
	box-shadow: none;
}

:global(#essexPricingAppRoot .p-datatable).changeLogTable td,
:global(#essexPricingAppRoot .p-datatable).changeLogTable th {
	padding: 0.5rem 0.75rem;
	height: unset;
	border-collapse: collapse;
}

:global(#essexPricingAppRoot .p-datatable).changeLogTable :global(.p-datatable-thead > tr > th) {
	height: unset;
	border: 1px solid #c8c8c8;
	border-bottom-width: 3px;
}

:global(#essexPricingAppRoot .p-datatable).changeLogTable :global(.p-datatable-tbody > tr > td) {
	border: 1px solid #c8c8c8;
}

.changeLogSkinnyColumn {
	width: 100px;
	white-space: nowrap;
}

.changeLogTimeColumn {
	width: fit-content;
	white-space: nowrap;
}

:global(.p-datatable .p-datatable-tbody > tr > td).changeLogCheckColumn {
	width: fit-content;
	white-space: nowrap;
	width: 50px;
	text-align: center;
}

:global(#essexPricingAppRoot .p-datatable .p-datatable-thead > tr > th).changeLogNumberColumn,
:global(.p-datatable .p-datatable-tbody > tr > td).changeLogNumberColumn {
	text-align: right;
	width: 100px;
	white-space: nowrap;
}

.changeLogCommentColumn {
	min-width: 250px;
	max-width: 500px;
	overflow-y: auto;
	white-space: wrap;
}
.changeLogOverrideReasonColumn {
	min-width: 150px;
	max-width: 400px;
	overflow-y: auto;
	white-space: wrap;
}
