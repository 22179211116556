//make sure to always wrap css in a parent selector
//because this gets added to the global stylesheet
.properties-page {
	padding-bottom: 2rem;

	a,
	a:visited,
	a:link {
		color: #009;
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}

	/*eat this specificity dang you!*/
	#property-list-wrapper .property-list.p-datatable .p-datatable-tbody>tr {
		&>td.property-list_column {
			vertical-align: top;

			.p-row-toggler {
				width: auto;
				height: auto;
			}
		}

		&>td.property-list-expander {
			vertical-align: middle;
			text-align: center;
		}
	}

	.property-list_column {

		//each data piece is in a div.
		//give it some space
		&>div {
			display: flex;
			flex-direction: column;
			gap: 8px;

			a {
				width: fit-content;
			}
		}
	}

	.wow {
		//week over week, fixed width a bit to reduce screen jitter on changes
		//min-width: 40px;
		display: inline-block;
	}

	.wow .pi {
		//week over week icons
		font-size: 0.9em;
		padding-right: 0.25rem;
	}


	.p-dropdown-label {
		font-size: 14px;
	}

	.p-inputtext {
		min-height: 28px !important;
		font-size: 14px;
		padding: 0.329rem;
	}

	.p-dropdown-trigger {
		width: 1.8rem !important;
	}

	.filter-headers-1 {
		width: 100%;
		display: flex;
		padding: 16px 16px 0;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 10px 20px;
		margin-bottom: 10px;
	}

	.filter-headers-2 {
		width: 100%;
		display: flex;
	}

	.filter-item {
		display: flex;
		flex-direction: column;

		&__title {
			font-weight: bold;
			font-size: 14px;
		}
	}

	.priority-filter-col {
		display: flex;
		//flex-direction: column;
		//flex-wrap: wrap;
		//font-size: 14px;
		//flex-basis: 1;
		//flex-shrink: 0;
		//flex-grow: 1;
		//background-color: yellow;
	}

	.priority-filter-actions {
		display: flex;
		justify-content: center;
		align-content: flex-start;
		align-self: center;
		//background-color: blueviolet;
	}

	.priority-apply-button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 16px 16px 0;
		margin-top: 8px;
		//background-color: black;
	}

	.applyButton,
	.applyButton2 {
		align-items: center;
		//min-height: 40px;
		min-width: 100px;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;

		//offset to match validation wrappers around dropdowns
		margin-bottom: 3px;
	}

	.applyButton2 {
		box-shadow: 0 0 0 0.2rem #8dcdff;
	}

	.error-message-styling {
		margin-top: 60px;
		text-align: center;
	}

	.pageWrapper {
		width: 100%;
		padding: 1rem;
	}

	.expansionWrapper {
		display: flex;
		padding: 15px 15px 15px 25px;
	}

	.expansionChild {
		background-color: white;
		border: 2px solid black;
		display: flex;
		padding: 15px 15px 15px 15px;
	}

	.expansionLeftColumn {
		display: flex;
		flex-direction: column;
		width: 50%;
	}

	.expansionRightColumn {
		display: flex;
		flex-direction: column;
		margin-left: 80px;
		margin-top: 30px;
	}

	.baseRent {
		padding-bottom: 30px;
	}

	.dataTablestyling {
		padding: 10px 10px 10px 10px;
	}

	.property-list {
		margin: 10px 0;
	}

	.property-list.dirty .p-paginator {
		//hide paginator when the table is dirty
		//we don't want them navigating when there are unsaved changes
		visibility: hidden;
	}

	.summaryInfo {
		padding: 20px 0 5px 15px;
		font-size: 14px;
		font-weight: bold;
	}

	.checkbox {
		margin-right: 40px;
	}

	.invisible {
		visibility: hidden;
	}

	/*
	Hide multi-select's select all checkbox
	Add spacing between check and dropdown button
*/

	//hide select all without hiding the whole panel
	.p-multiselect-panel .p-multiselect-header .p-checkbox {
		visibility: hidden;
		margin-right: 0.5rem;
		display: none;
	}

	//hide searchbar checkbox
	.p-multiselect-panel .p-multiselect-header .p-multiselect-close{
		visibility: hidden;
		margin-right: 0.5rem;
		display: none;
	}


	.p-multiselect .p-multiselect-clear-icon {
	margin-right: 6px;
	}

}


#essexPricingAppRoot .properties-page .p-datatable .p-datatable-thead>tr>th {
	height: auto;
}

