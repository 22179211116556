.createButton {
	display: flex;
	position: relative;
	margin-left: auto;
	// justify-self:flex-end;
	// align-self: flex-end;
}

.top-action-area {
	display: flex;
	flex-direction: row;	
	justify-content: flex-end;
}

.bottom-action-area {
	display: flex;
	flex-direction: row;	
	justify-content: flex-start;
	margin-top: 5px;
}

.create-action-area {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	margin-top: 10px;
}

#essexPricingAppRoot .grey-header tr > th {
	background: #f4f4f4 !important;
	height: auto;
}
