.unassigned-unit-types {
	padding-bottom: 2rem;

	.filter-headers-1 {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 10px 20px;
		margin-bottom: 10px;
	}
	
	.filter-item {
		display: flex;
		flex-direction: column;
	
		&__title {
			font-weight: bold;
			font-size: 14px;
		}
	}
	
	.filter-title{
	
		font-weight: bold;
		font-size: 14px;
	}
	
	.filter-controls {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	
		&__title {
			font-weight: bold;
			font-size: 14px;
		}
	}
	
	.navbarButton{
		margin-left: auto;
		margin-right: 10px;
	}

	.cancel-button {
		color: gray !important;
		background: #F0F0F0 !important;
		margin-right: 20px;
	}
	
	.cancel-button:hover {
		color: #F0F0F0 !important;
		background: #c75109 !important;
	}
	
	.unitgroup-list-table tr {
		height: 40px;
	}

}


