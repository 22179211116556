
:global(.p-datatable) table.metricsTable{
	 width: auto;
	 border-collapse: collapse;
	 border-spacing: 0;
	 table-layout: auto;
}

/* .metricsTableFieldHeading{} */

.metricsTableFieldValue td{
 white-space: nowrap;
 padding-bottom:10px;
 /* border-bottom:1px solid #CCC; */
}
