.unitGroupList {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	gap: 15px;
	padding-left: 35px;
}
.unitGroupList > div {
	width: 100%;
}

.unitGroupListHeader{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.unitGroupListHeaderTitleArea> h3 {
	padding: 0;
	margin: 0;
}

.unitGroupListHeaderActionsArea {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;
	white-space: nowrap;
}

