.advancedFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    margin-bottom: 30px;
    padding: 16px 16px 0;
}

.advancedFilters :global(.p-tree) {
    padding: 0.571rem 0.3rem;
	font-size: 14px;
	background: none;
    border: none;
}

.advancedFilters :global(.p-treenode-children) {
    padding: 0.5rem;
}

.advancedFilters :global(.p-tree-container) {
    overflow: inherit;
}

.advancedFilters :global(.p-treenode-content) {
    padding: 0rem !important;
}

.advancedFilters :global(.p-datepicker) table td {
    padding: 0;
}

.filterLevel {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px 20px;
	width:fit-content;
}

.calendarFilter {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.calendarFilter label {
    padding: 0px 0px 0px 3px;
}

.calendarOption {
    display: flex;
	flex-direction: row;
	align-items: center;	
	gap: 8px 4px;
}

.filterSectionTitle {
	margin-bottom: 8px;
	white-space: nowrap;
	font-weight: bold;
    font-size: 14px;
}

.unitGroupFilterSection {
    border-left: 1px dotted #848484;
    padding-left: 10px;
    vertical-align: middle;
    flex-shrink: 2;
    flex-grow: 0;
}

.propertyFilterSection {
    flex-shrink: 0;
}


