
.manage-unit-group-master-page{

    .create-unit-group-master-form {
        display: flex;
        flex-direction: column;
    }

    .update-unit-group-master-form {
        display: flex;
        flex-direction: column;
    }

    .p-inputtext.p-component{
        max-width: 50%;
    }
    
    
    .cancel-button {
        color: gray !important;
        background: #F0F0F0 !important;
        margin-right: 20px;
    }

    .cancel-button:hover {
        color: #F0F0F0 !important;
        background: #c75109 !important;
    }
    
    .save-button{
        margin-left: auto;
    }

    .label {
        font-weight: 700;
        margin-left: 5px;
        margin-bottom: 5px;	
        display: inline-block;												
    }
    
    .star {
        color: red;
    }	
}
