@import-normalize;

/* ^ what's up with this bad normalize.css import? - @Dirk 8/30/22 */

@font-face {
	font-family: "raleway-regular";
	src: local("raleway-regular"), url(./fonts/raleway-regular.woff) format("woff");
}

@font-face {
	font-family: "raleway-bold";
	src: local("raleway-bold"), url(./fonts/raleway-bold.woff) format("woff");
}

@font-face {
	font-family: "OpenSans-Regular";
	src: local("OpenSans-Regular"), url(./fonts/OpenSans-Regular.woff) format("woff");
}

@font-face {
	font-family: "OpenSans-Bold";
	src: local("OpenSans-Bold"), url(./fonts/OpenSans-Bold.woff) format("woff");
}

html,
body {
	height: 100%;
	min-height: 100%;
	width: 100%;
	/* TODO Fonts in this project are all messed up.  Find the right font from Essex - @Dirk 8/4/22 */
	font-family: "OpenSans-Regular", Arial, Helvetica, sans-serif;

	/* primereact uses REM units so this updates the main font size */
	font-size: 16px;
}

body {
	height: 100%;
	min-height: 100vh;
	margin: 0px;
	background-color: #f8f8f8;
	background-repeat: repeat;
	cursor: default;
	overflow-y: scroll;
}

#root {
	height: 100%;
	min-height: 100%;
}
