.unit-group-admin-page {
	padding-bottom: 2rem;
}

.unitgroup-list-table > tbody > tr {
	cursor: pointer;
 }


.filter-headers-1 {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 10px 20px;
	margin-bottom: 10px;
}

.filter-item {
	display: flex;
	flex-direction: column;

	&__title {
		font-weight: bold;
		font-size: 14px;
	}
}

.filter-title{

	font-weight: bold;
	font-size: 14px;
}

.filter-controls {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	&__title {
		font-weight: bold;
		font-size: 14px;
	}
}

.createButton {
	display: flex;
	position: relative;
	margin-left: auto;
	// justify-self:flex-end;
	// align-self: flex-end;
}

.unitgroup-list-table tr {
	height: 40px;
}