.create-classification-page {

  .create-classification-form {
    display: flex;
    flex-direction: column;
  }

  .create-classification-wrapper {
    max-width: 100%;
  }

  .cancel-button {
    background: #F0F0F0 !important;
    color: gray !important;
    margin-right: 20px;
  }

  .cancel-button:hover {
    background: #C75109 !important;
    color: #F0F0F0 !important;
  }

  .save-button {
    margin-left: auto;
  }

  div {																					
    .label {
      font-weight: 700;
      margin-left: 5px;
      margin-bottom: 5px;	
      display: inline-block;												
    }
    .star {
      color: red;
    }												

  }
  

  .inputs {
    max-width: 500px;
  }
}
