
.manage-bedroom {
	padding-left: 1rem;					
	div {
		.gap {
			display: flex;
			flex-direction: column;
			padding-top: 5px;	
			padding-bottom: 5px;	
		}
		div {																					
			.label {
				font-weight: 700;
				margin-left: 5px;
				margin-bottom: 5px;	
				display: inline-block;												
			}
			.star {
				color: red;
			}												
			.bedroom-input {
				display: flex;
				flex-direction: row;
				width: 400px !important;
				padding-top: 5px;				
			}
        }
    }
}
    
.cancel-button {
    color: gray !important;
    background: #F0F0F0 !important;
    margin-right: 20px;
}

.cancel-button:hover {
    color: #F0F0F0 !important;
    background: #c75109 !important;
}

.save-button{
    margin-left: auto;
}
