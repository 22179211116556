
.exceptions-page {
	padding-bottom: 2rem;

	a,
	a:visited,
	a:link {
		color: #009;
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}

    .filter-headers-1 {
		width: 100%;
		display: flex;
		padding: 16px 16px 0;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 10px 20px;
		margin-bottom: 30px;
	}

	.filter-headers-2 {
		width: 100%;
		display: flex;
	}

    .filter-item {
		display: flex;
		flex-direction: column;

		&__title {
			font-weight: bold;
			font-size: 14px;
		}
	}

	.applyButton,
	.applyButton2 {
		align-items: center;
		//min-height: 40px;
		min-width: 100px;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;

		//offset to match validation wrappers around dropdowns
		margin-bottom: 3px;
	}

	.applyButton2 {
		box-shadow: 0 0 0 0.2rem #8dcdff;
	}
}